import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { AlertController } from '@ionic/angular';
import { RestProvider } from 'src/app/providers/rest/rest';
import { DataProvider } from 'src/app/providers/data/data';
export class IconInputSettings {
    constructor() {
        this.placeholder = '';
        this.type = 'text';
        this.icon = '';
        this.styles = '';
        this.enabled = true;
        this.readonly = false;
        this.verify = null;
        this.loading = false;
        this.value = '';
        this.action = null;
        this.options = [];
        this.selectFailAdd = true;
        this.selectFirst = true;
        this.pattern = null;
    }
}
export class IconInputComponent {
    constructor(rest, alert, dataProvider) {
        this.rest = rest;
        this.alert = alert;
        this.dataProvider = dataProvider;
        this.settings = new IconInputSettings();
        this.action = new EventEmitter();
        this.customCSS = '';
        this.valueChange = new EventEmitter();
        this.manualAdd = new EventEmitter();
        this.date = new Date();
        this.min = this.date.getFullYear() - 50;
        this.max = this.date.getFullYear() + 50;
        this.alertCtrl = new AlertController();
        this.selectOptions = {
            header: this.settings.placeholder,
            cssClass: this.customCSS
        };
    }
    set placeholder(value) {
        this.settings.placeholder = value;
    }
    set type(value) {
        this.settings.type = value;
    }
    set icon(value) {
        this.settings.icon = value;
    }
    set autocomplete(value) {
        this.settings.autocomplete = value;
    }
    set styles(value) {
        this.settings.styles = value;
    }
    set enabled(value) {
        this.settings.enabled = value;
    }
    set readonly(value) {
        this.settings.readonly = value;
    }
    set pattern(value) {
        this.settings.pattern = value;
    }
    set title(value) {
        this.settings.title = value;
    }
    set inputPattern(value) {
        this.settings.inputPattern = value;
    }
    set selectFirst(value) {
        this.settings.selectFirst = value;
    }
    set selectFailAdd(value) {
        this.settings.selectFailAdd = value;
    }
    set verify(value) {
        this.settings.verify = value;
    }
    set loading(value) {
        this.settings.loading = value;
    }
    set options(value) {
        this.settings.options = value;
    }
    set value(value) {
        this.settings.value = value;
    }
    ngOnInit() {
        if (this.settings.type === 'date') {
            this.settings.icon = 'calendar';
            if (this.settings.value === 'today') {
                this.settings.value = new Date().toISOString();
            }
        }
        else if (this.settings.type === 'select' && this.settings.selectFirst) {
            if ((this.settings.value === '' || this.settings.value == null) && this.settings.options.length > 0) {
                this.settings.value = this.settings.options[0].value;
            }
        }
        else if (this.settings.type === 'search-select' && this.settings.selectFirst) {
            if ((this.settings.value === '' || this.settings.value == null) && this.settings.options.length > 0) {
                this.settings.value = this.settings.options[0];
            }
        }
        else if (this.settings.type === 'search-select-driver' && this.settings.selectFirst) {
            if ((this.settings.value === '' || this.settings.value == null) && this.settings.options.length > 0) {
                this.settings.value = this.settings.options[0];
            }
        }
        setTimeout(() => {
            this.inputChange();
            if (this.inputArea && this.inputArea.nativeElement) {
                this.inputArea.nativeElement.addEventListener('keyup', (event) => {
                    if (event.which === 13) { // If the input is active and 'Enter' has been pressed.
                        this.action.emit();
                    }
                });
            }
        }, 0);
    }
    ionViewDidEnter() {
    }
    inputChange() {
        if (this.settings.inputPattern && !this.inputArea.nativeElement.checkValidity()) {
            this.inputArea.nativeElement.reportValidity();
            this.valueChange.emit('');
            return;
        }
        if (this.settings.type === 'date' && this.settings.value) {
            this.valueChange.emit(this.settings.value.split('T')[0]);
        }
        else if (this.settings.type === 'search-select') {
            this.valueChange.emit(this.settings.value);
        }
        else if (this.settings.type === 'search-select-driver') {
            this.valueChange.emit(this.settings.value);
        }
        else {
            this.valueChange.emit(this.settings.value);
        }
        if (this.settings.pattern && this.settings.value) {
            setTimeout(() => {
                const regex = new RegExp(this.settings.pattern);
                const match = (regex.exec(this.settings.value) || [''])[0];
                this.settings.value = match;
                this.valueChange.emit(this.settings.value);
            });
        }
    }
    select_SelectableAction(event) {
        // Fix for Ionic Selectable search footer blocking select input.
        if (event.isSelected && this.selectSearch.searchText.length > 1) {
            this.settings.value = event.item;
            this.selectSearch.confirm();
            this.selectSearch.close();
        }
    }
    select_SelectableActionDriver(event) {
        // Fix for Ionic Selectable search footer blocking select input.
        if (event.isSelected && this.selectSearch.searchText.length > 1) {
            this.settings.value = event.item;
            this.selectSearch.confirm();
            this.selectSearch.close();
        }
    }
    click_Root() {
        if (this.settings.type === 'button' || this.settings.type === 'submit') {
            this.action.emit();
            this.inputArea.nativeElement.blur();
        }
        else if (this.settings.type === 'date') {
            this.inputArea.open();
        }
        else if (this.settings.type === 'select') {
            this.selectOptions.cssClass = this.customCSS;
            this.selectOptions.header = this.settings.placeholder;
            this.inputArea.open();
        }
        else if (this.settings.type === 'search-select') {
            // this.selectOptions.header = this.settings.placeholder;
            if (!this.selectSearch.isOpened) {
                this.selectSearch.open();
            }
        }
        else if (this.settings.type === 'search-select-driver') {
            if (!this.selectSearch.isOpened) {
                this.selectSearch.open();
            }
        }
        else {
            this.inputArea.nativeElement.focus();
        }
    }
    click_SelectableAddDriver(first_name, surname) {
        if (first_name.settings.value == '' || surname.settings.value == '') {
            alert('You must enter a first name and a surname');
            return false;
        }
        const foundDriver = this.selectSearch.items.find((x) => x.label.toLowerCase() === (first_name.settings.value.toLowerCase() + ' ' + surname.settings.value.toLowerCase()));
        if (foundDriver) {
            alert('There is a driver that already exists');
            return false;
        }
        else {
            this.alertCtrl = new AlertController();
            this.alertCtrl.create({
                header: 'Add new Driver',
                message: 'Are you sure you want to add ' + first_name.settings.value + ' ' + surname.settings.value + '?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {
                            return false;
                        }
                    },
                    {
                        text: 'Ok',
                        handler: () => {
                            const newItem = {
                                label: first_name.settings.value + ' ' + surname.settings.value,
                                value: { id: -1, first_name: first_name.settings.value, surname: surname.settings.value, customer: 22, creation_source: 2 }
                            };
                            this.manualAdd.emit(newItem.value);
                            this.selectSearch.addItem(newItem).then(() => {
                                this.selectSearch.close();
                                // console.log(this.settings.value)
                                this.settings.value = newItem;
                                // console.log(this.settings.value)
                                setTimeout(() => {
                                    this.inputChange();
                                    // const foundObject = this.selectSearch.items.find((x: any) => x.value === -1);
                                    // if (foundObject) {
                                    //   this.selectSearch.deleteItem(foundObject);
                                    // }
                                }, 1);
                            });
                        }
                    }
                ],
                backdropDismiss: false
            }).then(alertCtrl => alertCtrl.present());
        }
    }
    click_SelectableAdd(valToTrim) {
        // Remove any white space or spaces in registrations
        let val = valToTrim.replace(/\s/g, "");
        val = val.toUpperCase();
        const foundReg = this.selectSearch.items.find((x) => x.label.toLowerCase() === val.toLowerCase());
        if (foundReg) {
            alert('There is a vehicle that already exists in the list');
            return false;
        }
        else {
            this.alertCtrl = new AlertController();
            this.alertCtrl.create({
                header: 'Add new Registration',
                message: 'Are you sure you want to add ' + val + '?',
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {
                            return false;
                        }
                    },
                    {
                        text: 'Ok',
                        handler: () => {
                            this.manuallyAddVehicle(val);
                            // this.manualAdd.emit(newItem.value);
                            // this.selectSearch.addItem(newItem).then(() => {
                            //   this.selectSearch.close();
                            //   this.settings.value = newItem;
                            //   setTimeout(() => {
                            //     this.inputChange();
                            //     // const foundObject = this.selectSearch.items.find((x: any) => x.value === -1);
                            //     // if (foundObject) {
                            //     //   this.selectSearch.deleteItem(foundObject);
                            //     // }
                            //   }, 1);
                            // });
                        }
                    }
                ],
                backdropDismiss: false
            }).then(alertCtrl => alertCtrl.present());
        }
    }
    focus() {
        // console.log('Focus Please');
        setTimeout(() => {
            this.inputArea.nativeElement.focus();
        }, 50);
    }
    manuallyAddVehicle(reg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // create the vehicle on datalive
            const cust = yield this.dataProvider.getUserProfile();
            const newItem = {
                label: reg,
                value: { id: -1, registration: reg }
            };
            let newVehicle = {
                external_id: null,
                registration: reg,
                vehicle_type: null,
                creation_source: 2,
                customer: cust.customer
            };
            // const createdVehicle: any = await this.rest.createVehicle(newVehicle);
            this.rest.createVehicle(newVehicle).then(createdVehicle => {
                newVehicle.external_id = createdVehicle['id'];
                newVehicle = Object.assign({}, newVehicle, createdVehicle);
                this.manualAdd.emit(newVehicle);
                newItem.value.id = newVehicle.external_id;
                this.selectSearch.addItem(newItem).then(() => {
                    this.selectSearch.close();
                    this.settings.value = newItem;
                    setTimeout(() => {
                        this.inputChange();
                    }, 1);
                });
            }, err => {
                if (err.status && err.status == 400) {
                    const dataBody = err.error;
                    let errorMessage = '';
                    for (const [key, value] of Object.entries(dataBody)) {
                        console.log(`${key}: ${value}`);
                        errorMessage += `${key}: ${value[0]}`;
                    }
                    this.alert = new AlertController();
                    this.alert.create({
                        header: 'An issue with this registration',
                        message: errorMessage,
                        buttons: [
                            {
                                text: 'Cancel',
                                role: 'cancel',
                                handler: () => {
                                    return false;
                                }
                            }
                        ],
                        backdropDismiss: false
                    }).then(alertCtrl => alertCtrl.present());
                }
            });
        });
    }
}
