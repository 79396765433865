import * as tslib_1 from "tslib";
import { CustomerModel, Defect, DefectStatus } from '../models/user-cust-models';
import { ToastController, AlertController } from '@ionic/angular';
export class GlobalVariable {
    constructor(toastController, alertController) {
        this.toastController = toastController;
        this.alertController = alertController;
        this.retries = 0;
        this.repairStatuses = null;
        this.addedNotes = 0;
        this.userDepots = null;
        this.registrations = null;
        this.drivers = null;
        this.requireReauthentication = false;
        this.defectReadonly = false;
    }
    isUserLoggedIn() {
        if (this.user != null && this.user.user != null) {
            if (this.user.user.id == null) {
                return false;
            }
            return true;
        }
        return false;
    }
    toast(message, duration = 3000, options, cssClass) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message: message,
                duration: duration,
                buttons: options,
                cssClass: 'notification-toast ' + cssClass
            });
            toast.present();
        });
    }
    alert(header, message, buttons) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: header,
                message: message,
                buttons: buttons
            });
            alert.present();
        });
    }
    DefectDetails(statusID) {
        const key = Object.values(DefectStatus).find((x) => x.id == statusID);
        return key;
    }
    StripNulls(obj) {
        let strippedObject = {};
        Object.keys(obj).forEach(key => {
            // we need to account for the fact that a property could be an array
            // otherwise we will create an object where the key is the array index
            // and the value is each indexes value {0: {}, 1: {}}
            if (obj && obj.constructor === Array && obj.length > 0) {
                strippedObject = obj.map(item => this.StripNulls(item));
            }
            else if (obj[key] && typeof obj[key] === 'object') {
                // If the property is an object then we will strip that too recursively.
                strippedObject[key] = this.StripNulls(obj[key]);
            }
            else if (obj[key] !== null) {
                strippedObject[key] = obj[key];
            }
        });
        return strippedObject;
    }
    AtRepairer(defectStatus) {
        return defectStatus === DefectStatus.At_Repairer.id ||
            defectStatus === DefectStatus.Signed_Off_Roadworthy.id;
    }
    getDefectStatusActionTrailText(defect) {
        let actiontext = '';
        switch (defect.status) {
            case DefectStatus.Verified_Signed_Off.id:
                actiontext = `Manager signoff with manager editing driver defect`;
                break;
            case DefectStatus.At_Repairer.id:
                actiontext = `Defect assigned to a repairer ${defect.repairer.name}`;
                break;
            case DefectStatus.Signed_Off_Roadworthy.id:
                actiontext = `Defect set to Roadworthy from ${defect.repairer.name}`;
                break;
            case DefectStatus.Repaired.id:
                actiontext = `Defect Repaired`;
                break;
            default:
                break;
        }
        return actiontext;
    }
}
