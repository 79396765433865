import { Router } from '@angular/router';
import { AuthService } from '../providers/auth/auth.service';
import { GlobalVariable } from '../providers/global/global';
import { DataProvider } from '../providers/data/data';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../providers/auth/auth.service";
import * as i2 from "../providers/global/global";
import * as i3 from "@angular/router";
import * as i4 from "../providers/data/data";
export class AuthGuard {
    constructor(authService, global, router, dataProvider) {
        this.authService = authService;
        this.global = global;
        this.router = router;
        this.dataProvider = dataProvider;
    }
    canActivate(route, state) {
        return this.authService.checkToken().then((value) => {
            if (this.authService.authenticationState.getValue()) {
                if (!AuthService.accessToken && this.authService.token) {
                    AuthService.accessToken = this.authService.token;
                    this.authService.user = this.authService.getUserProfile();
                    this.dataProvider.getCustomer().then(customer => {
                        this.global.customer = customer;
                    });
                }
                const hasAccessToApp = !!this.authService.user.extra_vars.permissions.find((x) => x.ExternalAppReference === environment.appReference);
                if (!hasAccessToApp) {
                    this.router.navigateByUrl(`/restricted`);
                }
                return hasAccessToApp;
            }
            else {
                this.router.navigateByUrl(`/login?returnUrl=${state.url}`);
                return false;
            }
        }).catch((error) => {
            return false;
        });
    }
    canActivateChild(route, state) {
        return this.canActivate(route, state);
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.GlobalVariable), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.DataProvider)); }, token: AuthGuard, providedIn: "root" });
