import * as tslib_1 from "tslib";
import { AlertController } from '@ionic/angular';
import * as i0 from "@angular/core";
export class AlertServiceService {
    constructor() {
        this.alertCtrl = new AlertController();
    }
    show(input) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = false;
            const alert = yield this.alertCtrl.create({
                header: input.heading,
                message: input.messageText,
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        handler: () => {
                            result = false;
                        }
                    },
                    {
                        text: 'Ok',
                        role: 'true',
                        handler: () => {
                            result = true;
                        }
                    }
                ],
                backdropDismiss: false
            });
            yield alert.present();
            let clicked = yield alert.onDidDismiss();
            return this.newState = result;
        });
    }
}
AlertServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertServiceService_Factory() { return new AlertServiceService(); }, token: AlertServiceService, providedIn: "root" });
