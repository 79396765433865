import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GlobalVariable } from '../global/global';
import { Defect, DefectDetails, DefectCategories, PaginatedResult, IDefectNote, AddedSignature, IDriverQuestionsList, IDepotConfig, IDepot, IDepotConfigResponse } from '../models/user-cust-models';
export class RestProvider {
    constructor(http, global) {
        this.http = http;
        this.global = global;
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
        this.jwt = '';
        this.$isLoading = new BehaviorSubject(false);
    }
    /*
    * Global is loading obserable
    */
    setIsLoading(loadingValue) {
        this.$isLoading.next(loadingValue);
    }
    getIsLoading() {
        return this.$isLoading.asObservable();
    }
    Login(data) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.post(environment.url + '/api/authenticate/', JSON.stringify(data), { headers })
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    getUserInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.jwt}`);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders
            };
            const authUrl = environment.authUrl;
            const data = yield fetch(authUrl + "userinfo", requestOptions);
            // const data = await fetch(`${environment.urlFMT}/fmt/user/get_current_user_with_image/`, requestOptions);
            return yield data.json();
        });
    }
    GetCurrentUser() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlFMT + '/fmt/user/get_current_user_with_image/')
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    DepotLocations() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.get(environment.urlFMT + '/fmt/vehicle_groups/v2/', { headers })
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    DepotRegistrations(depotID) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.get(environment.urlFMT + '/fmt/paginated_vehicles_v2/?pageSize=75&page=1&ordering=registration&search=&vgid=' + depotID, { headers })
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    GetDefects(depots, status, repairFilter, nameFilter, regFilter, vehTypeFilter, defectNumber, fullDefectNumber) {
        this.setIsLoading(true);
        const requestDate = Date.now();
        return new Promise((resolve, reject) => {
            this.http.get(
            // API
            `${environment.url}/defect/?vehicle__isnull=False` +
                // - Defect Status
                `${status && status.length > 0 ? '&status=' + status : ''}` +
                // - Driver name
                `${(nameFilter) ? '&driver_signature_object__printed_name__icontains=' + nameFilter : ''}` +
                // - Vehicle Reg
                `${(regFilter) ? '&server_vehicle_registration_when_created__icontains=' + regFilter : ''}` +
                // - Defect Assigned Repairer
                `${(repairFilter && repairFilter !== 'none') ? '&repairer__icontains=' + repairFilter : ''}` +
                // - Vehicle Depot
                `${(depots && depots !== []) ? '&vehicle_group__id=' + depots.join(',') : ''}` +
                // - Vehicle Type & Date Filter
                `${vehTypeFilter === true ? '&ordering=-vehicle__vehicle_type_name,-server_created_date' : ''}` +
                // - Repairer user defect number
                `${defectNumber ? '&defect_number__contains=' + defectNumber : ''}` +
                // - Full Defect number for filtering
                `${fullDefectNumber ? '&full_defect_number=' + fullDefectNumber : ''}`).subscribe(res => {
                resolve(Object.assign({}, res, { timestamp: requestDate }));
                this.setIsLoading(false);
            }, (err) => {
                reject(err);
                this.setIsLoading(false);
            });
        });
    }
    GetNextDefectsPage(next) {
        this.setIsLoading(true);
        return new Promise((resolve, reject) => {
            this.http.get(next).subscribe(res => {
                resolve(res);
                this.setIsLoading(false);
            }, (err) => {
                reject(err);
                this.setIsLoading(false);
            });
        });
    }
    GetDefectsAllStatus(status, repairFilter, depotFilter, nameFilter, regFilter, vehTypeFilter) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.url + `/defect/?vehicle__isnull=False&status=${status}` +
                `${(nameFilter) ? '&driver_signature_object__printed_name__icontains=' + nameFilter : ''}` +
                `${(regFilter) ? '&server_vehicle_registration_when_created__icontains=' + regFilter : ''}` +
                `${(repairFilter && repairFilter !== 'none') ? '&repairer__icontains=' + repairFilter : ''}` +
                `${(depotFilter && depotFilter !== 'none') ? '&vehicle_group__id=' + depotFilter : ''}` +
                `${vehTypeFilter === true ? '&ordering=-vehicle__vehicle_type_name,-server_created_date' : ''}`).subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    DefectGet(uuid) {
        this.setIsLoading(true);
        return new Promise((resolve, reject) => {
            this.http.get(environment.url + `/defect/${uuid}/`)
                .subscribe(res => {
                this.setIsLoading(false);
                resolve(res);
            }, (err) => {
                this.setIsLoading(false);
                reject(err);
            });
        });
    }
    DriverList(pageNum) {
        this.setIsLoading(true);
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlFMT + `/drivers_list/?ordering=first_name&page=${pageNum}`).subscribe(res => {
                this.setIsLoading(false);
                resolve(res);
            }, (err) => {
                this.setIsLoading(false);
                reject(err);
            });
        });
    }
    VehicleList(pageNum, vgid) {
        this.setIsLoading(true);
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlFMT +
                `/fmt/simple_vehicle_list/?pageSize=75&ordering=registration&search=&vgid=${vgid}&page=${pageNum}`).subscribe(res => {
                this.setIsLoading(false);
                resolve(res);
            }, (err) => {
                this.setIsLoading(false);
                reject(err);
            });
        });
    }
    GetVehiclesWithoutDepots() {
        this.setIsLoading(true);
        return new Promise((resolve, reject) => {
            this.http.get(environment.urlFMT +
                `/fmt/simple_vehicle_list/?restrict_vg_null=true&creation_source=2`).subscribe((res) => {
                this.setIsLoading(false);
                resolve(res.results);
            }, (err) => {
                this.setIsLoading(false);
                reject(err);
            });
        });
    }
    /**
     * Search vehicle simple list
     * @param searchTerm
     * @returns IPaginatedSimpleVehicleResponse
     */
    getSimpleVehicleList(searchTerm) {
        // All the magic happens in these two line:
        const _paginationParams = { pageSize: 50, page: 1, orderingDirection: '-', ordering: 'registration', search: searchTerm, 'allow_vg_null': true, 'customer_vehicles_only': true };
        const _httpParams = JSON.parse(JSON.stringify(_paginationParams));
        const params = new HttpParams({
            fromObject: _httpParams,
        });
        return this.http.get(environment.urlFMT + '/fmt/simple_vehicle_list/', { params: params });
    }
    /**
     * Search vehicle simple list
     * @param searchTerm
     * @returns IPaginatedSimpleVehicleResponse
     */
    getDriverList(searchTerm) {
        // All the magic happens in these two line:
        const _paginationParams = { pageSize: 30, page: 1, orderingDirection: '-', ordering: 'first_name', search: searchTerm };
        const _httpParams = JSON.parse(JSON.stringify(_paginationParams));
        const params = new HttpParams({
            fromObject: _httpParams,
        });
        // return this.http.get<IPaginatedSimpleVehicleResponse>( environment.urlFMT +'/fmt/simple_vehicle_list/', { params: params });
        return this.http.get(environment.urlFMT + `/drivers_list/`, { params: params });
    }
    VehicleListGroup(vgid, pageNum = 1, vehicleList = []) {
        return new Promise((res, reject) => {
            this.VehicleList(pageNum, vgid).then((result) => {
                vehicleList = vehicleList.concat(result.results);
                console.log('GetVehiclesWithoutDepots - page: ', pageNum, 'VGID: ', vgid);
                if (result.next) {
                    this.VehicleListGroup(vgid, pageNum + 1, vehicleList).then((nextResult) => res(nextResult));
                }
                else {
                    res(vehicleList);
                }
            }), (err) => {
                reject(err);
            };
        });
    }
    DriverListAsync() {
        return new Promise((res, reject) => {
            this.DriverList(1).then((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const pagesNeededToFetch = Math.ceil(result.count / result.results.length);
                const maxRequests = 15;
                let currentRequests = [];
                currentRequests.push(result);
                let pagesFetched = 1;
                let isLoadingRequests = true;
                let combinedResult = [];
                while (isLoadingRequests) {
                    if (pagesFetched + currentRequests.length <= pagesNeededToFetch && currentRequests.length < maxRequests) {
                        currentRequests.push(this.DriverList(pagesFetched + currentRequests.length));
                    }
                    else {
                        // Force a rejected promise - TESTING
                        // currentRequests.push(this.rejectTimeout(new Error("Out of fruits!"), 2000));
                        try {
                            const vehicleBatch = yield Promise.all(currentRequests);
                            pagesFetched += vehicleBatch.length;
                            currentRequests = [];
                            vehicleBatch.forEach((x) => {
                                combinedResult = combinedResult.concat(x.results);
                            });
                            if (pagesNeededToFetch) {
                                console.log(`[API] 🔃 Driver Fetched ${pagesFetched - 1}/${pagesNeededToFetch} pages`);
                            }
                            if (pagesFetched >= pagesNeededToFetch || !pagesNeededToFetch) {
                                isLoadingRequests = false;
                                res(combinedResult);
                            }
                        }
                        catch (error) {
                            // Catch a promise.all(). In a Promise.all(), Even if one promise fails it will break out of completing them all
                            console.log(error); // Console out the error
                            console.log(`[API ERROR] Driver List | There are ${result.count} drivers in total, pagesNeededToFetch: ${pagesNeededToFetch} | pagesFetched: ${pagesFetched} | currentRequests: ${currentRequests.length} | current page number: ${pagesFetched + currentRequests.length + 1}`);
                            isLoadingRequests = false;
                            // reject(combinedResult); // still return results
                            res(combinedResult); // still return results
                        }
                    }
                }
            }), (err) => {
                debugger;
                console.log(`[API] Error - DriverListAsync`);
                reject(err);
            });
        });
    }
    rejectTimeout(reason, delay) {
        return new Promise((r, reject) => setTimeout(() => reject(reason), delay));
    }
    VehicleListGroupAsync(vgid) {
        return new Promise((res, reject) => {
            this.VehicleList(1, vgid).then((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const pagesNeededToFetch = Math.ceil(result.count / result.results.length);
                const maxRequests = 15;
                let currentRequests = [];
                let pagesFetched = 1;
                let isLoadingRequests = true;
                let combinedResult = result.results;
                while (isLoadingRequests) {
                    console.log(`[API] 🚗 VGID: ${vgid} | ${result.count} vehicles, pagesNeededToFetch: ${pagesNeededToFetch} | pagesFetched: ${pagesFetched} | currentRequests: ${currentRequests.length} | current page number: ${pagesFetched + currentRequests.length + 1}`);
                    if (pagesFetched + currentRequests.length + 1 <= pagesNeededToFetch && currentRequests.length < maxRequests) {
                        currentRequests.push(this.VehicleList(pagesFetched + currentRequests.length + 1, vgid));
                    }
                    else {
                        // Force a rejected promise - TESTING
                        // currentRequests.push(this.rejectTimeout(new Error("Out of petrol!"), 1000));
                        try {
                            const vehicleBatch = yield Promise.all(currentRequests);
                            console.log(`[AWAIT  FINISHED] vehicleBatch: `, vehicleBatch);
                            pagesFetched += vehicleBatch.length;
                            currentRequests = [];
                            vehicleBatch.forEach((x) => {
                                combinedResult = combinedResult.concat(x.results);
                            });
                            if (pagesNeededToFetch) {
                                console.log(`[API] 🔃 Fetched VGID: ${vgid}: ${pagesFetched - 1}/${pagesNeededToFetch} pages`);
                            }
                            if (pagesFetched >= pagesNeededToFetch || !pagesNeededToFetch) {
                                isLoadingRequests = false;
                                res(combinedResult);
                            }
                        }
                        catch (error) {
                            // Catch a promise.all 1 second. in a Promise.all(), Even if one promise fails it will break out of completing them all
                            console.log(error); // Console out the error
                            console.log(`[API ERROR] VGID: ${vgid} | ${result.count} vehicles, pagesNeededToFetch: ${pagesNeededToFetch} | pagesFetched: ${pagesFetched} | currentRequests: ${currentRequests.length} | current page number: ${pagesFetched + currentRequests.length + 1}`);
                            isLoadingRequests = false;
                            res(combinedResult);
                            // reject(error);
                        }
                    }
                }
            }), (err) => {
                debugger;
                // isLoadingRequests = false;
                console.log(`[API] Error - VehicleListGroupAsync`);
                reject(err);
            });
        });
    }
    VehicleListAllAsync() {
        return new Promise((res, reject) => {
            const usersDepots = this.global.userDepots;
            let cachedVehicles;
            let cachedVehicleTime = null;
            if (cachedVehicles) {
                const syncTimeout = 10;
                const timeDiff = Date.now() - cachedVehicleTime;
                const minutesApart = Math.round(((timeDiff % 86400000) % 3600000) / 60000);
                console.log(`[API] ⏰ Last vehicle sync ${minutesApart} minute(s) ago`);
                if (minutesApart < syncTimeout) {
                    console.log(`[API] 🌐 We synced under ${syncTimeout} minute(s) ago - serving cached vehicles list`);
                    res(cachedVehicles);
                    return;
                }
                else {
                    console.log(`[API] ❌ Vehicle cache expired ${syncTimeout - minutesApart} minute(s) ago. Refreshing...`);
                }
            }
            this.DepotLocations().then((locations) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let vehicles = [];
                this.global.userDepots = locations;
                for (const location of locations) {
                    try {
                        const vehicleResult = yield this.VehicleListGroupAsync(location.id);
                        vehicles = vehicles.concat(vehicleResult);
                        // Set vehicles per depot
                        console.log(`[API] 🏁 Depot got (${location.name} ${location.id}). Total Vehicles: ${vehicles.length}`);
                        // TODO - removed the logalstorageas it was hitting stirage limit on user with all dpd depots
                        // localStorage.setItem('registrations_' + location.id, JSON.stringify({
                        //   time: Date.now(),
                        //   vehicles
                        // }));
                    }
                    catch (error) {
                        console.log(`[API] ❌ VehicleListGroupAsync await sync error`);
                        reject(vehicles);
                    }
                }
                const vehiclesND = yield this.GetVehiclesWithoutDepots();
                vehicles = [...vehicles, ...vehiclesND];
                console.log(`[API] 🏁 Done. Total Vehicles: ${vehicles.length}`);
                // localStorage.setItem('registrations_no-depots', JSON.stringify({
                //   time: Date.now(),
                //   vehicles
                // }));
                res(vehicles);
            }));
        });
    }
    DefectCreate(defect) {
        this.setIsLoading(true);
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.post(environment.url + '/defect/', defect, { headers })
                .subscribe(res => {
                this.setIsLoading(false);
                resolve(res);
            }, (err) => {
                this.setIsLoading(false);
                reject(err);
            });
        });
    }
    DefectInit(defect) {
        return new Promise((resolve, reject) => {
            // We dont want to send an empty array of question_responses to the API on create
            if (defect.question_responses && defect.question_responses.length === 0) {
                delete defect.question_responses;
            }
            let defectStripped = Object.assign({}, defect);
            delete defectStripped.defect_details;
            defectStripped = this.global.StripNulls(defectStripped);
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.patch(environment.url + `/defect/${defect.uuid}/update_and_create_defect_num/`, defectStripped, { headers })
                .subscribe(res => {
                this.DefectDetailsUpdate(defect.defect_details, defect).then((result) => {
                    resolve(res);
                }).catch((error) => {
                    reject(error);
                });
            }, (err) => {
                reject(err);
            });
        });
    }
    DefectUpdate(defect) {
        return new Promise((resolve, reject) => {
            // We dont want to send an empty array of question_responses to the API on update
            if (defect.question_responses && defect.question_responses.length === 0) {
                delete defect.question_responses;
            }
            let defectStripped = Object.assign({}, defect);
            delete defectStripped.defect_details;
            defectStripped = this.global.StripNulls(defectStripped);
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.patch(environment.url + `/defect/${defect.uuid}/`, defectStripped, { headers })
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    DefectCancel(defect) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.patch(environment.url + `/defect/${defect.uuid}/cancel/`, {}, { headers })
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    DefectDetailsUpdate(defectDetails, defect) {
        return new Promise((resolve, reject) => {
            const promiseArray = [];
            let count = 0;
            defectDetails.forEach(element => {
                if (defect) {
                    element.defect = defect.uuid;
                    element.status = defect.status;
                }
                if (element.index == null) {
                    element.index = count;
                }
                if (element['id'] != null) {
                    promiseArray.push(this.DefectDetailPatch(element));
                }
                else {
                    element.new_signoff_action_required = true;
                    promiseArray.push(this.DefectDetailCreate(element));
                }
                count++;
            });
            Promise.all(promiseArray).then((values) => {
                resolve();
            }).catch((error) => {
                reject(error);
            });
        });
    }
    DefectDetailPatch(defectDetails) {
        this.setIsLoading(true);
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.patch(environment.url + `/defect_detail/${defectDetails.uuid}/`, defectDetails, { headers })
                .subscribe(res => {
                resolve(res);
                this.setIsLoading(false);
            }, (err) => {
                reject(err);
                this.setIsLoading(false);
            });
        });
    }
    DefectDetailCreate(defectDetails) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.post(environment.url + `/defect_detail/`, defectDetails, { headers })
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    DefectEmail(uuid, email) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.post(environment.url + `/defect/${uuid}/email_report/`, { email_to: email }, { headers })
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    DefectNotePost(note) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.post(environment.url + `/defect_action/`, note, { headers })
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    DefectNotes(uuid) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.get(environment.url + `/defect/${uuid}/actions/`, { headers })
                .subscribe((res) => {
                // console.log('Notes;',res);
                resolve(res['results']);
            }, (err) => {
                reject(err);
            });
        });
    }
    DefectPDF(uuid) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.get(environment.url + `/defect/${uuid}/generate_pdf/`, { headers })
                .subscribe((res) => {
                // console.log('PDF', res);
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    GetRepairers(vehicleGroup) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.get(environment.urlFMT + `/vehicles_group_optimized/${vehicleGroup}/`, { headers })
                .subscribe((res) => {
                if (res && res.vehicle_group_contacts) {
                    resolve(res.vehicle_group_contacts);
                }
                else {
                    reject();
                }
            }, (err) => {
                reject(err);
            });
        });
    }
    GetCustomerRepairers(customerID) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.get(environment.urlFMT + `/customer_repairers/${customerID}/`, { headers })
                .subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    GetRepairerTechs(repairerID) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.get(environment.urlFMT + `/${repairerID}/technicians/`, { headers })
                .subscribe((res) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    // Should be able to remove this.
    // check to make sure everything works before doing so.
    // UpdateDefectAndDefectDetails(defect: Defect) {
    //   // Copy the defect to a new object.
    //   let tempDefect = {...defect};
    //   // Now copy the defect details to send.
    //   let defectDetailsToSend:DefectDetails[] = [...tempDefect.defect_details];
    //   // Renove the defect details from the main defect and remove all nulls for patching.
    //   tempDefect.defect_details = null;
    //   let defectToSend:Defect = this.global.StripNulls(tempDefect);
    //   // Wrap everything in a promise that we can return.
    //   return new Promise((resolve, reject) => {
    //     // First we are going to send off the defect report.
    //     this.DefectPatch(defectToSend).then((defectResult) => {
    //       // If that comes back good then we will send the details.
    //       // console.log('Defect Patched. Now for details...');
    //       let promiseArray = [];
    //       // For each detail we will create a promise and add it to an array.
    //       defectDetailsToSend.forEach(element => {
    //         // Set the UUID of the Defect Details object.
    //         // the api will reject if this is not done.
    //         // Do these need their own UUID's generated or use the defect report one?
    //         // UUID now created per details row in the front end.    element.uuid = defect.uuid;
    //         element.status = defect.status;
    //         element.defect = defect.uuid;
    //         if(element['id'] != null) {
    //           promiseArray.push(this.DefectDetailPatch(element));
    //         } else {
    //           promiseArray.push(this.DefectDetailCreate(element));
    //         }
    //       });
    //       // When all promises have resolved then we move on.
    //       Promise.all(promiseArray).then((values) => {
    //         // Resolve as everything was good!
    //         // console.log('Defect Details Updated!')
    //         resolve();
    //       }).catch((error) => {
    //         // Something went wrong.
    //         // console.log('Failed to patch all defect details.');
    //         reject(error);
    //       })
    //     }).catch((error) => {
    //       // console.log('Failed to patch defect report.');
    //       reject(error);
    //     })
    //   });
    // }
    // DefectPatch(defect:Defect) {
    //   return new Promise((resolve, reject) => {
    //     const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    //     this.http.patch(environment.url + `/defect/${defect.uuid}/`, defect, {headers})
    //       .subscribe(res => {
    //         resolve(res);
    //       }, (err) => {
    //         reject(err);
    //       });
    //   });
    // }
    GetTemplateDefectCatagories() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.get(environment.url + `/template_defect_categories/`, { headers })
                .subscribe(res => {
                const result = res;
                resolve(result.results);
            }, (err) => {
                reject(err);
            });
        });
    }
    DefectAddSignature(defectUUID, addedSiganture) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.post(environment.url + `/defect/${defectUUID}/add_signature/`, addedSiganture, { headers })
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    RepairerAuthID(techPin, repairerID) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.post(environment.urlFMT + `/validate_tech_pin/`, { pin: techPin, repairer: repairerID }, { headers })
                .subscribe(res => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }
    RepairerDetail(id) {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.get(environment.urlFMT + `/repairers/${id}/`, { headers })
                .subscribe((res) => {
                resolve(res.results);
            }, (err) => {
                reject(err);
            });
        });
    }
    RepairerStatus() {
        return new Promise((resolve, reject) => {
            const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
            this.http.get(environment.url + `/defect_repair_status_definitions/`, { headers })
                .subscribe((res) => {
                resolve(res.results);
            }, (err) => {
                reject(err);
            });
        });
    }
    registrationLookup(vehicle) {
        return this.http.get(`${environment.urlFMT}/vehicles/`, { headers: this.headers, params: { registration: vehicle.registration } }).toPromise();
    }
    createVehicle(vehicle) {
        return this.http.post(`${environment.urlFMT}/vehicles/`, vehicle, { headers: this.headers }).toPromise();
    }
    createDriver(driver) {
        return this.http.post(`${environment.urlFMT}/drivers_list/`, driver, { headers: this.headers }).toPromise();
        //return this.http.post(`http://localhost:8080/api/drivers_list/`, driver, { headers: this.headers }).toPromise();
    }
    // Driver Question API's
    // getDriverQuestions(custId: number): Promise<any> {
    //   return this.http.get(
    //     `${environment.urlFMT}customer_questions_list/${custId}/`,
    //     { headers: this.headers }
    //   ).toPromise();
    // }
    getDriverQuestions(custId, depotId) {
        this.setIsLoading(true);
        return new Promise((resolve, reject) => {
            this.http.get(`${environment.url}/customer_questions_list/${custId}/${depotId}/`).subscribe((res) => {
                this.setIsLoading(false);
                resolve(res);
            }, (err) => {
                this.setIsLoading(false);
                reject(err);
            });
        });
    }
    getDepotConfig(external_id) {
        let url = `${environment.url}/depot_config/`;
        if (Array.isArray(external_id)) {
            url += `?depot_ids=${external_id.join(',')}`;
        }
        else {
            url += `${external_id}/`;
        }
        return new Promise((resolve) => {
            this.http.get(url).subscribe(res => resolve(res.results), _err => resolve([]));
        });
    }
    getVehicleDepots(vehicle_id) {
        return new Promise((resolve) => {
            this.http.get(`${environment.urlFMT}/vehicle/${vehicle_id}/vehicle_groups/`)
                .subscribe(res => resolve(res), _err => resolve([]));
        });
    }
}
